.main-div{
  display: flex;
  background-color: black;
  height: 30px;  
  z-index: 31;
  position: fixed;
  top: 10px;
  right: 15px; 
}
.toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: center;    
    align-content: center;
    align-items: center;
    background: transparent;
    border: none;
    cursor: pointer;    
    padding: 0;
    box-sizing: border-box; 
    margin-left: 3px;  
  }
  
  .toggle-button:focus {
    outline: none;
  }
  
  .toggle-button__line {
    width: 20px;
    height: 3px;
    margin-top: 3px;
    margin-bottom: 2px;
    background: white;
  }

  @media screen and (min-width: 769px){
    .main-div{
        display: none;
    }
}