.side-drawer {
    display: flex;
    justify-content: center;
    height: 225px;
    width: 100%;    
    background-color: black;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 56px;
    right: 0;    
    max-width: 400px;
    z-index: 200;
    transform: translateX(100%);
    transition: transform 0.3s ease-out;    
  }
  
  .side-drawer.open {
    transform: translateX(0);
  }

  .side-drawer ul {
      
      height: 100%;
      width: 100%;
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-self: flex-start;
      
  }

  .side-drawer a {   
    display: flex;
    justify-content: center;      
    color: black;
    width: 100%;      
    font-size: 1.2rem;
    text-decoration: none;
  }
  .side-drawer a:hover,
  .side-drawer a:active
   {
       color: white;
       text-decoration: none;
   }

  .side-drawer li {
      margin: 0.5rem 0;
  }